import React, { useEffect, useState } from 'react';

import FilterItem from './FilterItem'

import { Container } from './styles'

const FilterList = ({filters, action, visible = true}) => {
  const [active, setActive] = useState(null);
  const [show, setShow] = useState(null);
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    var itens = [];
    filters.forEach(item => {
      if(item?.filter?.length > 0){
        itens.push(item)
      }else if (item.type.toLowerCase() === 'tamanho'){
        itens.push(item)
      }
    })

    if(itens.length === 1){
      handleSelect(itens[0])
    }

    setFilterList(itens)
  }, [])

  if(visible === false){
    visible = filterList.length > 1
  }

  function handleSelect(item){
    setActive(item.id);
    setShow(item.id);
    action(item)
  }

  return (
    <Container visible={visible}>
      {filters &&
        filterList.map(item => (
          <FilterItem
            key={item.type + item.id}
            active={item.id === active}
            disable={show !== null && item.id !== show}
            action={() => handleSelect(item)}
            name={item.name}
          />
        ))
      }
    </Container>
  );

};

export default FilterList;
