import GlobalStyles from './styles/global'
import Route from './routes'

function App() {
  return (
    <>
      <GlobalStyles />
      <Route />
    </>
  );
}


export default App;
