import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.div`
  width: 280px;
  margin-bottom: 40px;
  
  @media screen and (min-width: 1024px){
    &:not(:nth-child(3n-3)){
      margin-right: 16px;
    }

  }


  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: calc((100% / 2) - 16px);

  }
  
  img{
    width: 280px;
    height: 280px;
    margin-bottom: 16px;
    display: block;
    border-radius: 4px;
    box-shadow: 0 -8px 16px 0 #eeeeee;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 100%;
      height: auto;
    }
  }
`

export const Title = styled.h2`
  line-height: 1.2;
  color: ${theme.colors.darkGray};
  font-size: 20px;
  margin:0 auto 8px;
  width: 248px;
  font-family: "HelveticaNeueRoman";
  font-weight: 400;
` 

export const Category = styled.span`
  font-family: "HelveticaNeueRoman";
  margin:0 auto;
  width: 248px;
  font-size: 12px;
  line-height: 1.33;
  color: ${theme.colors.darkGray};
  display: block;
`