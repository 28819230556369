import React from 'react'
import * as S from './styles'

import icon from '../../assets/icons/logout-orange.svg'

import { Link } from 'react-router-dom'

const Button = () => (
  <S.Wrapper> 
    <Link to={'/'}>
      Sair / Logout <img src={icon} alt="Icon Logout"/>
    </Link>
  </S.Wrapper>
)


export default Button