import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'

import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import CategoryCard from '../../components/CategoryCard'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import Footer from '../../components/Footer'
import LoadingModal from '../../components/LoadingModal'

import redirectLogin from '../../functions/redirectLogin'
import api, { lojaId } from '../../services/api'

const MainCategory = () => {
  const history = useHistory()
  const [dataCategory, setDataCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [active, setActive] = useState(null);
  const [load, setLoad] = useState(true)
 
  useEffect(() => {
    redirectLogin()
  }, [])

  const fetchData = async () => {
    const response = await api.get('/Product/GetCategories', {
      params:{
        id: lojaId 
      }
    })

    setLoad(false)
    return response.data
  }

  const setItems = async () => {
    const dataItems = await fetchData()

    const mainData = {
      data: dataItems,
      expire: 5 * 60 * 1000 + Date.now(),
    }

    dataItems.filter((item, index) => {
      if(index >= 1 ) {
        item.remove = true
      } else {
        item.disable = false
      }
    })

    localStorage.setItem('mainData', JSON.stringify(mainData))
    setDataCategory(dataItems)
  
  }

  useEffect(() => {
    
    localStorage.removeItem('objFilter')
    const mainData = localStorage.getItem('mainData')

    if(mainData && Date.now() <= JSON.parse(mainData).expire) {
      setLoad(false)
      setDataCategory(JSON.parse(mainData).data)
    } else {
      setItems()
    }

  }, [])

  const handleClick = (item) => {
    setSelectedCategory(item)
    setActive(item.id)
  }

  const handleNext = () => {
    if(selectedCategory.key === 'tenis'){
      const objFilter = {
        categoryId: selectedCategory.id,
        genreId: 0,
        sizeId: 0,
        storeId: lojaId,
        subcategoryId: 0,
        typeId: 0,
        keyName: selectedCategory.key
      }
      localStorage.setItem('objFilter',  JSON.stringify(objFilter))
      history.push('/produtos')
    } else {
      localStorage.setItem('obj',  JSON.stringify(selectedCategory));
      history.push('/sub-categoria')
    }
  }

 
  return (
    <>
      <Header 
        currentStep={2}
        textPage={'Escolha a '} 
        nameCategory={'categoria'}  
      />
      <Breadcrumb list={['Categorias']} /> 
      <S.Wrapper>
        {load && <LoadingModal />}
        
        <Title>Escolha a <span>categoria principal</span></Title>
        <Subtitle text={'Que tipo de produto que você quer customizar?'} />

        <S.CardContent >
          {dataCategory !== null && dataCategory.map((item) =>  {
            if(item.remove) {
              return
            } 
            else {
              return(
                <CategoryCard 
                  action={() => handleClick(item)} 
                  key={item.id} 
                  title={item.name} 
                  img={item.path} 
                  active={item.id === active} 
                  disable={item.disable}
                  remove={item.remove}
                />

              )
            }
          } )}
        <CategoryCard 
          action={null} 
          key={14} 
          title={'Tops'} 
          img={'https://nsps.nike.t4.com.br/uploads/0/produto_tipo/14_1.png'} 
          active={false} 
          disable={true}
          remove={false}
        />
        <CategoryCard 
          action={null} 
          key={17} 
          title={'Camisetas'} 
          img={'https://nsps.nike.t4.com.br/uploads/0/produto_tipo/17_1.png'} 
          active={false} 
          disable={true}
          remove={false}
        />
        <CategoryCard 
          action={null} 
          key={15} 
          title={'Tênis'} 
          img={'https://nsps.nike.t4.com.br/uploads/0/produto_tipo/15_1.png'} 
          active={false} 
          disable={true}
          remove={false}
        />
        </S.CardContent>

        <S.ButtonContent>
          <Button href={'/'} color={'transparent'}>Voltar</Button>
          <Button disabled={!active} onClick={handleNext}>Próxima Etapa</Button>
        </S.ButtonContent>
      </S.Wrapper>
      <Footer />
    </>
  )
}

export default MainCategory