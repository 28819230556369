import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'

import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import CategoryCard from '../../components/CategoryCard'
import Button from '../../components/Button'
import UndoButton from '../../components/UndoButton'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'

import redirectLogin from '../../functions/redirectLogin'

const SubCategory = () => {
  const history = useHistory()
  const [dataCategory, setDataCategory] = useState(null)
  const [active, setActive] = useState(null)

  useEffect(() => {
    redirectLogin()
  }, [])

  useEffect(() => {
    if(!localStorage.getItem('obj')){
      alert('Ocorreu um erro')
      window.location.href = "/categoria"
    } else {
      const json = JSON.parse(localStorage.getItem('obj'))
      setDataCategory(json.subCategories)
    }
  }, [])

  const handleClick = (item) => {
    localStorage.setItem('sub', JSON.stringify(item)); 
    setActive(item.id)
  }

  const handleNext = () => {
    history.push('/opcoes')
  }

  return(
    <>
      <Header currentStep={2} textPage={'Escolha a '} nameCategory={'categoria'} />
      <Breadcrumb list={['Categorias', JSON.parse(localStorage.getItem('obj')) && `${JSON.parse(localStorage.getItem('obj')).name}`]} />
      <S.Wrapper>
        <UndoButton />
        <Title>Escolha a <span>sub-categoria</span></Title>
        <Subtitle text={'Qual o produto que você quer customizar?'} />

        <S.CardContent >
        {dataCategory !== null && dataCategory.map((item) => 
          <CategoryCard 
            action={() => handleClick(item)} 
            key={item.id} 
            title={item.name} 
            img={item.path} 
            active={item.id === active} 
          />
        )}
        </S.CardContent>

        <S.ButtonContent>
          <Button href={'/categoria'} color={'transparent'}>Redefinir</Button>
          <Button disabled={!active} onClick={handleNext}>Próxima Etapa</Button>
        </S.ButtonContent>
      </S.Wrapper>
      <Footer />
    </>
  )
}

export default SubCategory