import React from 'react'
import * as S from './styles'

import bgImg from '../../assets/background-404.jpg'

const ProductCard = ({img, title = 'teste', category , action}) => (
  <S.Wrapper className={'productCard'} onClick={action}>
    <img src={img ? img : bgImg } alt={title}/>
    <S.Title className={'productCardName'}>{title}</S.Title>
    <S.Category className={'productCardDescription'}>{category}</S.Category>
  </S.Wrapper>
)

export default ProductCard