import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.section`
  background-color: ${theme.colors.softGray};
  padding: 48px 76px 44px;
  position: relative;
  min-height: 750px;
  min-height: calc(100vh - 340px);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 48px 40px 44px;
  }
  .subtitle{
    margin-bottom: 40px;
  }
  .undo-bt{
    position: absolute;
    top: 25px;
    left: 53px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      left: 27px;
    }
  }
  &.category_tenis{
    .productCard {
      width: 424px;
      height: 320px;
      border-radius: 4px;
      box-shadow: 0 11px 14px -10px #e8e8e8;
      border-left: 2px solid ${theme.colors.orange};
      background-color: white;
      margin-bottom: 32px;
      position: relative;
      &:not(:nth-child(2n-2)){
        margin-right: 24px; 
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 43vw;
        height: 29vw;
        &:not(:nth-child(2n-2)){
          margin-right: 10px; 
        }
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
      }
      &Name {
        position: absolute;
        top: 24px;
        left: 24px;
        font-family: "TradeGothic";
        font-size: 20px;
        line-height: 1.2;
        color: #54595e;
        text-transform: uppercase;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          font-size: 15px;
        }
      }
      &Description {
        display: none;
      }
    }
  }
`

export const WrapperProducts = styled.div`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  max-height: 801px;
  width: 928px;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    justify-content: space-between;
    
  }
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    background-color: #e8e8e8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #54595e;
    border-radius: 4px;
  }

`