import React from 'react'
import * as S from './styles'

import ProgressItem from '../ProgressItem'
import LogoNike from '../LogoNike'
import ProgressTitle from '../ProgressTitle'
import Button from '../Button'

import iconLogout from '../../assets/icons/logout.svg'
import logoHeader from '../../assets/texto-header.svg'

const Header = ({ currentStep, nameCategory, textPage }) => (
  <S.Wrapper >
    <S.WrapperSteps>
      <ProgressItem text={'Página Principal'}  active={currentStep} number={1}/>
      <ProgressItem text={'Categorias'}  active={currentStep} number={2}/>
      <ProgressItem text={'Produtos'}  active={currentStep} number={3}/>
      <ProgressItem text={'Personalize'}  active={currentStep} number={4}/>
      <ProgressItem text={'Finalizar'}  active={currentStep} number={5}/>
      <LogoNike />
    </S.WrapperSteps>
   
    <ProgressTitle currentStep={currentStep} nameCategory={nameCategory} textPage={textPage} />
   
    <img className='logo-header' src={logoHeader} alt='Texto escrito, feito por você acima da Logo da Nike'/>

    <Button 
      size={'medium'} 
      color={'gray'} 
      uppercase={true}
      href={'/'}
    > 
      Sair da Personalização <img alt='Botão de Logout' src={iconLogout} />
    </Button>
  </S.Wrapper>
)

export default Header