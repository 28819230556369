import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home'
import MainCategory from './pages/MainCategory'
import SubCategory from './pages/SubCategory'
import ProductsList from './pages/ProductsList'
import Finished from './pages/Finished'
import CustomizeOption from './pages/CustomizeOption'
import CustomizationTool from './pages/CustomizationTool'
import Login from './pages/Login'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path = '/categoria' component={MainCategory} />
      <Route path ='/sub-categoria' component={SubCategory} />
      <Route path ='/produtos' component={ProductsList} />
      <Route path ='/pedido-confirmado' component={Finished} />
      <Route path ='/opcoes' component={CustomizeOption} />
      <Route path ='/customizacao' component={CustomizationTool} />
      <Route exact path ='/' component={Home} />
    </Switch>
  </BrowserRouter>
)


export default Routes;