import React, { useState } from "react";
import * as S from "./styles";

import arrow from "../../assets/icons/rectangle.svg";
import leftArrow from "../../assets/icons/arrow-right.svg";
import LogoNike from '../LogoNike'

const Breadcrumb = ({ open, list }) => {
  const [locked, setLocked] = useState(false);
  return (
    <S.Wrapper>
      <S.Container>
        {list.map(name => <S.Breadcrumb key={name}>{name}</S.Breadcrumb> )}

        <img className="left-arrow" src={leftArrow} alt="" />
        <img className="arrow" src={arrow} alt="" />
      </S.Container>
      <S.HelpButton open={locked} onClick={() => setLocked(!locked)} > 
        <S.Modal open={locked}>
          <S.CloseBt onClick={() => setLocked(!locked)} />
          <LogoNike />
          <S.ModalTitle>Precisando de <span>ajuda</span>?</S.ModalTitle>
          <S.ModalText>Magna est deserunt sint do ex proident excepteur nulla aute id. Id sint nulla dolor anim fugiat velit incididunt reprehenderit ullamco duis laborum. NostrReprehenderit laborum occaecat dolor voluptate eu aliqua ullamco. Esse dolor velit ipsum irure occaecat Lorem non enim quis deserunt dolor excepteur ullamco. Ipsum labore dolore id aliqua do cupidatat. Ad anim in quis ullamco duis eiusmod nostrud dolore id laborum.ud pariatur in tempor nisi sit exercitation Lorem adipisicing exercitation veniam culpa esse. Fugiat dolor veniam est ad irure incididunt aliquip. <b>DASDAS</b> labore ad esse consequat in eu eu tempor nisi minim dolor excepteur duis.</S.ModalText>
          <S.Bt onClick={() => setLocked(!locked)}>Ok</S.Bt>
          <S.Border />
        </S.Modal>
      </S.HelpButton >
    </S.Wrapper>
  );
};

export default Breadcrumb;
