import styled from 'styled-components'

import checkDisable from '../../../assets/icons/c-check.svg'
import checked from '../../../assets/icons/checked.svg'

// export const Button = styled.button`
//   width: 50px;
//   height: 60px;

//   ${(props) =>
//     props.active &&
//     css`
//       background-color: green;
//     `}

//   ${(props) =>
//     props.disable &&
//     css`
//       display: none;
//     `}
// `;

export const Wrapper = styled.button`
  width: 424px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 11px 14px -10px #e8e8e8;
  background-color: #ffffff;
  border: 0;
  border-left: 2px solid #fe5000;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0 16px 0 24px;
  display: ${props => props.disable ? `none` : `flex`};
  position: relative;

  &:not(:last-child){
    margin-bottom: ${props => props.active ? `24px` : `8px`};
    /* margin-bottom: 8px; */
  }

  &:after{
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: ${props => props.active ? `url(${checked})` : `url(${checkDisable})`};
  }


`

export const Name = styled.span`
  font-family: "HelveticaNeueRoman";
  font-size: 14px;
  opacity: 0.9;
  line-height: 1.71;
  color: #54595e;
`