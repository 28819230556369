import React, {useEffect, useState} from 'react'
import * as S from './styles'

import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import UndoButton from '../../components/UndoButton'
import ProductCard from '../../components/ProductCard'
import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingModal from '../../components/LoadingModal'
import Footer from '../../components/Footer'

import api from '../../services/api'
import redirectLogin from '../../functions/redirectLogin'


const ProductsList = () => {
  const [data, setData] = useState(null)
  const [load, setLoad] = useState(true)
  const [className, setClassName] = useState('')

  useEffect(() => {
    redirectLogin()
  }, [])

  useEffect(() => {

    if(!localStorage.getItem('objFilter')){
      alert('Ocorreu um erro')
      window.location.href = "/categoria"
    } else {
      const objFilter = JSON.parse(localStorage.getItem('objFilter'))

      objFilter.keyName ? setClassName(objFilter.keyName) : setClassName('')
      
      // console.log('filter: ', objFilter);
      api.post('/Product/GetProducts', objFilter)
  
      .then(function (response) {
        setData(JSON.parse(response.request.response))
        // console.log(JSON.parse(response.request.response))
        setLoad(false)
      })
  
      .catch(function (error) {
        console.log(error);
      });
    }
  }, [])

  const handleClick = (item) => {
    localStorage.setItem('productCode', item.code)
    localStorage.setItem('productName', item.name)
    window.location.href = "/customizacao"
  }

  return(
    <>
      <Header currentStep={3} textPage={'Escolha o '} nameCategory={'produto'} />
      <Breadcrumb list={['Produtos']} />
      <S.Wrapper className={`category_${className}`}>
        {load && <LoadingModal />}
        <UndoButton />
        <Title>Escolha o <span>produto</span></Title>
        <Subtitle text={'Selecione uma das opções deste protudo'} />

        <S.WrapperProducts>
          {data && data.map(item => 
            <ProductCard
              action={() => handleClick(item)} 
              key={item.code} 
              title={item.name} 
              img={`/assets/lista-produtos/produto-${item.skuNike}.png`} 
            />
          )}
        </S.WrapperProducts>
      </S.Wrapper>
      <Footer />
    </>
  )
}

export default ProductsList