import styled from 'styled-components'
import theme from '../../styles/theme'

import bgHome from '../../assets/bg-home.png'

export const Wrapper = styled.main`
  padding: 40px 76px 0;
  background-color: ${theme.colors.softGray};
  background-repeat: no-repeat;
  background-image: url(${bgHome});
  background-position: top center;
  height: 1366px;
  overflow-y: hidden;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 40px 40px 0;
  }
  .service-logo{
    margin-right: 208px;
  }  
  
  .progress{
    display: flex;
    margin-bottom: 42px;
    align-items: center;
    width: fit-content;
    padding-left: 4px;
    .bar{
      width: 160px;
      height: 2px;
      border-radius: 2px;
      margin-right: 16px;
      background-color: ${theme.colors.lightGray};
      &:first-child{
        background-color: ${theme.colors.orange};
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        width: 15vw;

      }
    }
  }
`

export const Title = styled.h1`
  font-size: 48px;
  line-height: 0.83;
  letter-spacing: -0.8px;
  color: ${theme.colors.black};
  text-transform: uppercase;
  font-family: 'TradeGothic';
  margin-bottom: 16px;
  position: relative;
  margin-top: 48px;
  &:after{
    content: '';
    height: calc(100% + 28px);
    background-color: ${theme.colors.orange};
    width: 4px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -24px;
  }
`

export const SmallText = styled.p`
  font-family: 'HelveticaNeueRoman';
  font-size: 18px;
  line-height: 1.33;
  color: ${theme.colors.darkGray};
  margin-bottom: 32px;
`

export const DoubleContent = styled.div`
  display: flex;
`

export const WrapperCards = styled.ul`
 
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  @media screen and (min-width: 768px) and (max-width: 840px) {
    margin-top: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 953px) {
    justify-content: unset;
    /* width: 95.2vw; */
  }
  @media screen and (min-width: 960px) and (max-width: 1023px) {
    justify-content: center;
    /* width: 95.2vw; */
  }

  &:after{
    content: '';
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7);
    width: 1024px;
    height: 374px;
    position: absolute;
    top: 576px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 100%;
    }
  }
`