import { createGlobalStyle } from 'styled-components'
import theme from './theme'

const GlobalStyles = createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family: 'TradeGothic';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/TradeGothicLT-BoldCondTwenty.woff') format('woff')
  }

  @font-face {
    font-family: 'TradeGothicCond';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/TradeGothicLT-CondEighteen.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeueMedium';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeue-Medium.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeueThin';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeue-Thin.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeueMediumItalic';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeue-MediumItalic.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeueLight';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeue-Light.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeueRoman';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeueLTStd-Roman.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeueBold';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeue-Bold.woff') format('woff')
  }

  @font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/HelveticaNeue.woff') format('woff')
  }


  body {
    font-family: ${theme.font.family};
    -webkit-font-smoothing:antialiased;
    font-size: 16px;
    max-width: 1024px;
    margin: 0 auto;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      max-width: 100%;
    }
  }
 
`

export default GlobalStyles