import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-family: "HelveticaNeueRoman";
  color: ${theme.colors.darkGray};
  text-align: center;
`