import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.div`
  position: relative;
  white-space: nowrap;

`

export const CurrentStep = styled.span`
  opacity: 0.4;
  -webkit-text-stroke: 1.2px #bfc1c19e;
  font-family: "TradeGothic";
  font-size: 120px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0);
`

export const TitleCategory = styled.h3`
  font-size: 16px;
  font-family: "HelveticaNeueRoman";
  line-height: 1.75;
  color: ${theme.colors.black};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 64px;
  font-weight: 400;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 14px;
  }


  span{
    font-weight: 700;
    color: ${theme.colors.orange};
  }
`