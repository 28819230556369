import styled from 'styled-components'
import theme from '../../styles/theme'

import checkDisable from '../../assets/icons/c-check.svg'
import checked from '../../assets/icons/checked.svg'

export const Wrapper = styled.div`
  position: relative;
  width: 424px;
  height: 320px;
  border-radius: 4px;
  box-shadow: 0 11px 14px -10px #e8e8e8;
  background-image: ${props => props.img ? `url(${props.img})` : ''};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-left: 2px solid ${theme.colors.orange};
  background-color: white;
  margin-bottom: 32px;
  position: relative;
  pointer-events: ${props => props.disable ? `none` : `unset`};
  display: ${props => props.remove ? `none` : ``};
  opacity: ${props => props.disable ? `0.5` : `unset`};
  &:not(:nth-child(2n-2)){
    margin-right: 24px; 
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 43vw;
    height: 29vw;
    &:not(:nth-child(2n-2)){
      margin-right: 10px; 
    }
  }
  &:after{
    content: '';
    width: 20px;
    height: 20px;
    top: 22px;
    right: 16px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: ${props => props.active ? `url(${checked})` : `url(${checkDisable})`};
  }
  `

export const Title = styled.h2`
  position: absolute;
  top: 24px;
  left: 24px;
  font-family: "TradeGothic";
  font-size: 20px;
  line-height: 1.2;
  color: #54595e;
  text-transform: uppercase;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 15px;
  }
`