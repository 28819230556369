import axios from 'axios'
// user/password: 'loja1'
const homologUrl = 'https://nsps.nike.t4.com.br/api'
const localUrl = 'https://localhost:5001'

const auth = JSON.parse(localStorage.getItem('auth'))

const api = axios.create({
  baseURL: homologUrl,
  headers: {
    Authorization: `Bearer ${auth ? auth.token : ''}`,
  }
}) 

export const lojaId = auth ? auth.storeId : ''

export default api