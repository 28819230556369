import React, { useEffect, useState } from 'react'
import * as S from './styles'

import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import UndoButton from '../../components/UndoButton'
import Timer from '../../components/Timer'
import api from '../../services/api'

const CustomizationTool = () => {
  const [code, setCode] = useState(null)
  const [finalization, setFinalization] = useState(null)
  const [env, setEnv] = useState('')

  useEffect(() => {
    const url = window.location.hostname.indexOf('localhost') === -1 ? '/personalizacao' : 'http://nsps.nike.local.t4.srv.br'
    setEnv(url)

    if(!localStorage.getItem('productCode')){
      alert('Ocorreu um erro')
      window.location.href = "/categoria"
    } else {
      const code = localStorage.getItem('productCode')
      setCode(code)
      
    } 

    // document.querySelector("iframe")[0].addEventListener('load', () => {
    // document.querySelector("iframe")[0].contentWindow.postMessage(
    //   {
    //       call: 'sendValue',
    //       value: 'data'
    //   },
    //   window.location.origin)
    // })
  }, [])

  useEffect(() => {
    const handler = event => {
      // console.log(event.data)
      if(event.data === 'false'){
        setFinalization(null)
      }
      if(event.data === 'true'){
        setFinalization(true)
      }
      if(event.data.operation === 'addCustomizedProduct'){
        const _cart = event.data.parameters
        const _data = event.data.dataUser
        api.post('\/Order\/CheckOut', {
          "storeId": _data.storeId,
          "orderId": _data.orderId,
          "name": _data.name,
          "phone": _data.phone,
          "email": _data.email,
          "news": true
        })

        .then(response => {
          localStorage.setItem('infoCustomizedProduct', JSON.stringify(_cart))
          window.location.href = "/pedido-confirmado"
        })

      }
    }

    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [])

  const handle = () => {
    console.log(
      document.querySelector('iframe')

    )
    // document.querySelector('iframe').postMessage(
    //   {call: 'sendValue',value: 'ddasdasdsaata'}
    //   , window.location.origin)
  }

  return (
    <>
      <Header 
        currentStep={finalization ? 5 : 4} 
        textPage={finalization ? 'Finalizar ' : 'Personalize seu ' } 
        nameCategory={finalization? 'personalização' : 'produto'} 
      />
      <Breadcrumb list={[finalization ? 'Finalizar' : 'Personalize']} />
      {code && 
        <S.Wrapper>
          <UndoButton />
          <iframe title="personalizacao"
            onLoad={handle}
            // src={`http://nsps.nike.local.t4.srv.br/frontend/v2/futebol/?idSku=194502178586%20%20%20%20%20%20%20%20&storeId=1#`}>  
            src={`${env}/frontend/v2/jersey/?idSku=${localStorage.getItem('productCode')}&storeId=${JSON.parse(localStorage.getItem('auth')).storeId}`}>  
          </iframe>
          <Timer />
        </S.Wrapper>
      }
    </>
  )
}

export default CustomizationTool