import React, {useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'

import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import UndoButton from '../../components/UndoButton'
import CategoryCard from '../../components/CategoryCard'
import Button from '../../components/Button'
import FilterList from '../../components/FilterList'
import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import Footer from '../../components/Footer'

import { lojaId } from '../../services/api'

const CustomizeOption = () => {
  const history = useHistory()
  const [content, setContent] = useState(null)
  const [type, setType] = useState(null)
  const [gender, setGender] = useState(null)
  const [userChoose, setUserChoose] = useState(null);
  const [titleInfo, setTitleInfo] = useState('')
  const [reset, setReset] = useState(false)
  const [button, setButton] = useState(false)

  const setInfos = () => {
    const json = JSON.parse(window.localStorage.getItem('sub'))
    const {id: categoryId} = JSON.parse(window.localStorage.getItem('obj'))

    if(json.filter[0].filter[0].filter[0]){
      setTitleInfo('Selecione o tipo')
    } else {
      setTitleInfo('Selecione o gênero')
    }

    setContent(json)
  
    let initialChoose = {
      categoryId: categoryId,
      subcategoryId: json.id,
      storeId: lojaId
    }
  
    setUserChoose(initialChoose)
  }

  useEffect(() => {
    setInfos()
  }, [])

  useEffect(() => {
    setInfos()
    setReset(false)
  }, [reset])

  const callbackFunction = (item) => {
    switch (item?.type) {
      case 'TIPO':
        setType(item)
        if(!item.filter[0].filter[0]){
          setTitleInfo('Selecione o tamanho')
        } else {
          setTitleInfo('Selecione o genero')
        }
        let typeChoice = {
          ...userChoose,
          typeId: item.id
        }
        
        setUserChoose(typeChoice)
        break;

      case 'GENERO':
        console.log(item.filter[0])
        setGender(item)
        // console.log(item)
        if(item.filter[0]){
          setTitleInfo('Selecione o tamanho')
        } 
        else {
          setTitleInfo('Confirme o produto que selecionou')
          setButton(true)
        }
        let genderChoice = {
          ...userChoose,
          genreId: item.id
        }
        
        setUserChoose(genderChoice)
        break;
        
        case 'TAMANHO':
          setTitleInfo('Confirme o produto que selecionou')
          setButton(true)
          let sizeChoice = {
            ...userChoose,
            sizeId: item.id
          }
          
          console.log('test', sizeChoice)
          setUserChoose(sizeChoice)
        break;

      default:
        break;
    }
  }

  const handleButton = (e) => {
    e.preventDefault();
    if(!type) {
      window.location.href = "/sub-categoria"
    } else {
      setType(null)
      setGender(null)
      setUserChoose(null)
      setContent(null)
      setReset(true)
    }
  }

  useEffect(() => {
    // console.log('Object to post: ', userChoose, content);
    localStorage.setItem('objFilter', JSON.stringify(userChoose));
  }, [userChoose])


  return(
    <>
      <Header currentStep={2} textPage={'Escolha a '} nameCategory={'categoria'} />
      <Breadcrumb 
        list={[
          'Categorias', 
          `${JSON.parse(localStorage.getItem('obj')).name}`, 
          `${JSON.parse(localStorage.getItem('sub')).name}`
        ]} 
      /> 
      <S.Wrapper>
        <UndoButton />
        <Title><span>Opções</span> adicionais</Title>
        <Subtitle text={titleInfo} />

        <S.WrapperObjects>
          {content && 
            <CategoryCard 
              title={`${content.name}`} 
              img={`${content.path}`} 
              active={true} 
            />
          }

          {content &&
            <FilterList
              key={content?.type + content.id}
              filters={content?.filter}
              action={callbackFunction}
              visible={false} // exibir se possuir apenas 1 opção | por padrão é true
            />
          }

          {type &&
            <FilterList
              key={type.type + type.id}
              filters={type?.filter}
              action={callbackFunction}
            />
          }

          {gender &&
            <FilterList
              key={gender.type + gender.id}
              filters={gender?.filter}
              action={callbackFunction}
            />
          }
        </S.WrapperObjects>
  
        <S.ButtonContent>
          <Button onClick={handleButton} href={'#'} color={'transparent'}>Voltar</Button>
          {button && <Button href={'/produtos'}>Avançar</Button>}
        </S.ButtonContent> 
      </S.Wrapper>
      <Footer />
    </>
  )
}

export default CustomizeOption