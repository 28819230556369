import React from 'react'
import * as S from './styles'

import bgImg from '../../assets/background-404.jpg'

const CategoryCard = ({title, img, active = false, action, disable = false, remove = false}) => (
  <S.Wrapper disable={disable} remove={remove}  onClick={action} className="category-card" img={img ? img : bgImg} active={active}>
    <S.Title>{title}</S.Title>
  </S.Wrapper>
)

export default CategoryCard