import React from 'react'
import * as S from './styles'

import Timer from '../Timer'

const Footer = () => (
  <S.Wrapper>
    <S.Text>Você será desconectado automaticamente após 120 segundos de inatividade.</S.Text>
    <Timer />
  </S.Wrapper>
)

export default Footer