import React from 'react'
import * as S from './styles'

const FilterItem = ({active = false, disable = false, name, action}) => (
  <S.Wrapper 
    active={active} 
    disable={disable}
    onClick={() => action()}
  >
    {name}
  </S.Wrapper>
)

export default FilterItem