import styled from 'styled-components'

export const Wrapper = styled.button`
  width: 135px;
  height: 40px;
  border-radius: 32px;
  border: solid 1.4px #fe5000;
  background-color: transparent;  
  padding: 0 23px;
  a {
    font-family: 'TradeGothic';
    justify-content: space-between;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: #fe5000;
    text-decoration: inherit;
    /* height: 100%; */
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
`