import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.section`
 background-color: ${theme.colors.softGray};
  padding: 48px 76px 108px;
  position: relative;
  min-height: calc(100vh - 340px);
  .subtitle{
    margin-bottom: 40px;
  }
  .undo-bt{
    position: absolute;
    top: 25px;
    left: 53px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      left: 27px;
    }
  }
`

export const WrapperObjects = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .category-card{
    margin-right: 0 !important;
    margin-bottom: 24px;
  }
`

export const WrapperChoice = styled.div`
  width: 424px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 11px 14px -10px #e8e8e8;
  background-color: #ffffff;
  border-left: 2px solid #fe5000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 24px;
  &:not(:last-child){
    margin-bottom: 24px;
  }
`

export const Text = styled.h2`
  font-family: "HelveticaNeueRoman";
  font-size: 14px;
  opacity: 0.9;
  line-height: 1.71;
  color: #54595e;
`

export const ButtonContent = styled.div`
  margin: 48px auto 0;
  width: fit-content;
  .bt{
    &:not(:last-child){
      margin-right: 16px;
    }
  }
`