import styled from 'styled-components'
import undoBt from '../../assets/icons/undo.png'


export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Img = styled.img.attrs({
  src: `${undoBt}`
})`
 
`

export const Text = styled.span`
  font-family: "HelveticaNeueRoman";
  font-size: 16px;
  line-height: 1.5;
  color: #bfc1c1;
  margin-left: -16px;

`