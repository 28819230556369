import React, { useEffect, useState } from 'react'
import * as S from './styles'

import Header from '../../components/Header'
import Breadcrumb from '../../components/Breadcrumb'
import ButtonLogout from '../../components/ButtonLogout'
import Footer from '../../components/Footer'
import LoadingModal from '../../components/LoadingModal'

import api from '../../services/api'

const Finished = () => {
  const [dataOrder, setDataOrder] = useState('')
  const [load, setLoad] = useState(true)
  const [qrCodeData, setQrCodeData] = useState(null)

  useEffect(() => {
    api.get(`/Order/GetOrder?orderId=${JSON.parse(localStorage.getItem('infoCustomizedProduct')).customid}`)
  
    .then(function (response) {
      const json = response.data

      json.custom.map(item => {
        item.details = item.details.split(';')
      })
      console.log(json)
      setDataOrder(json)
      setLoad(false)
    })

    .catch(function (error) {
      console.log(error);
      setLoad(false)
    });

    api.get(
      `/Order/GetQrCode?orderId=
      ${JSON.parse(localStorage.getItem('infoCustomizedProduct')).customid}`, 
      { responseType: 'arraybuffer'}
    )
  
    .then(function (response) {
          
      let image = btoa(
        new Uint8Array(response.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      setQrCodeData(
        `data:
        ${response.headers['content-type'].toLowerCase()}
        ;base64,
        ${image}`
      )
    })

    .catch(function (error) {
      console.log(error);
    });
  }, [])
  return (
    <>
      <Header currentStep={5} textPage={'Finalizar '} nameCategory={'personalização'}   />
      <Breadcrumb list={['Finalizar', 'Confirmação']} />
      <S.Wrapper>
        {load && <LoadingModal />}
        <S.Maintitle>Pedido Confirmado</S.Maintitle>
        {dataOrder &&
          <>
            <S.SubMaintitle>{dataOrder.status}</S.SubMaintitle>
            <S.Title>Acompanhe pelo <span>QRCode</span></S.Title>
            <S.Flex>
              <img 
                className='qr-code' 
                src={qrCodeData} 
                alt='QR Code'/>
              <div>
                <S.TitleNumber>Número do pedido</S.TitleNumber>
                <S.OrderNumber>{dataOrder.order ? dataOrder.order : 'Pendente' }</S.OrderNumber>
              </div>
            </S.Flex>
            <S.FlowingText>{localStorage.getItem('productName')}</S.FlowingText>
            {/* <S.BodyText>Maecenas vel viverra</S.BodyText> */}
            <S.InfoBlock>
              <S.InfoSection>
                <S.InfoTitle>Valor <span>Final</span> </S.InfoTitle>
                <S.InfoResult>R$ {dataOrder.value ? dataOrder.value : ''}</S.InfoResult>
              </S.InfoSection>
              <S.InfoSection>
                <S.InfoTitle><span>Tamanho</span> </S.InfoTitle>
                <S.InfoResult>{dataOrder.size}</S.InfoResult>
              </S.InfoSection>
              <S.InfoSection>
                <S.InfoTitle><span>Resumo</span> das Personalizações </S.InfoTitle>
                <S.BoxInfos>
                  {dataOrder.custom.map(item =>(
                    <span key={item.sideId}>
                      <S.BodyText>Lado {item.sideId} - {item.side}</S.BodyText>
                      {item.details.map(item => (
                        <S.InfoResult key={item}>{item}</S.InfoResult>
                      ))}
                    </span>
                  ))}
                </S.BoxInfos>
              </S.InfoSection>
            </S.InfoBlock>
            <ButtonLogout />
          </>
        }
      </S.Wrapper>
      <Footer />
    </>
  )
}

export default Finished