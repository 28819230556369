import React from 'react'
import * as S from './styles'

import { Link } from 'react-router-dom'

const Button = ({children, size = 'normal', href, color = 'black', uppercase = false, onClick, disabled }) => {
  href = href ? href : '#'
  return (
  <S.Wrapper onClick={onClick} className='bt' disabled={disabled} href={href} size={size} color={color} uppercase={uppercase}>
    <Link className='link' to={href} >
      {!!children && <span>{children}</span>}
    </Link>
  </S.Wrapper>
)}


export default Button