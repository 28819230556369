import styled from 'styled-components'
// import theme from '../../styles/theme'

export const Wrapper = styled.header`
  padding: 40px 76px 0;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  height: 192px;
  z-index: 2;
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 40px 40px 0;
  }
  /* box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08); */
  /* background-image: linear-gradient(to bottom, #ffffff, #f7f7f7); */
  .bt{
    margin-top: 32px;
  }
  .logo-header{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
  }
`

export const WrapperSteps = styled.ul`
  display: flex; 
  white-space: nowrap;
  width: fit-content;
`