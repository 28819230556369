import React from 'react'
import * as S from './styles'
import { Link } from 'react-router-dom'

const UndoButton = () => (
  <Link to='/'>
    <S.Wrapper className='undo-bt'>
      <S.Img />
      <S.Text>Desfazer</S.Text>
    </S.Wrapper>
  </Link>
)

export default UndoButton