import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.h3`
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.8px;
  color: ${theme.colors.darkGray};
  font-family: "HelveticaNeue";
  text-align: center;
  margin-bottom: 16px;

  span{
    color: ${theme.colors.orange};
    font-family: "HelveticaNeueMedium";
  }
`