import styled from 'styled-components'
import theme from '../../styles/theme'

import ajudaBt from '../../assets/icons/ajuda.svg'
import ajudaLaranjaBt from '../../assets/icons/ajuda-laranja.svg'
import divisoria from '../../assets/icons/divisoria-breadcrumb.svg'
import close from '../../assets/icons/close.svg'
import border from '../../assets/border-modal.png'

export const Wrapper = styled.div`
  z-index: 2;
  width: 100%;
  height: 58px;
  background-color: ${theme.colors.darkGray};
  border-top: 2px solid ${theme.colors.orange};
  position: relative;
  .left-arrow{
    width: 8px;
    height: 7px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 76px;
  }
`

export const Container = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.16), rgba(255, 255, 255, 0)), linear-gradient(to left, #ff8147, #fe5000 0%);
  padding-left: 92px;
  width: fit-content;
  height: 100%;
  display: flex;
  clip-path: polygon(0% 0%,calc(100% - 15px) 0%,100% 50%,calc(100% - 15px) 100%,0% 100%);
  .arrow{
    position: relative;
    right: -1.2px;
  }
`

export const Breadcrumb = styled.span`
  font-family: "HelveticaNeueRoman";
  color: white;
  line-height: 2;
  padding: 16px 40px 16px 0;
  /* background-color: #fe5000; */
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: fit-content;
  position: relative;
  opacity: 0.48;
  &:not(:nth-last-child(3)){
    &:after{
      content: '';
      height: 100%;
      width: 16px;
      background-image: url(${divisoria});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 16px;

    }
  }
  &:nth-last-child(3) {
    opacity: 1;
    padding-right: 17px
  }
  &:not(:nth-child(1)) + .left-arrow{
    opacity: 0.48;
  }
`

export const HelpButton = styled.span`
  text-align: center;
  background-image: ${props => props.open ? `url(${ajudaLaranjaBt})` : `url(${ajudaBt})`};
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: ${props => props.open ? '40px' : '89px'};
  height: ${props => props.open ? '40px' : '89px'};
  background-color: unset;
  border: unset;
  right: ${props => props.open ? '76px' : '52px'};
  outline: none;
`

export const Modal = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  width: 356px;
  /* height: 478px; */
  padding: 40px 24px 40px 21px;
  position: absolute;
  right: calc(41px + 20px);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.48);
  top: -32px;
  .logo-nike{
    margin-bottom: 24px;
  }
`
export const CloseBt = styled.span`
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-image: url(${close});
  background-size: contain;
  position: absolute;
  top: 28px;
  right: 28px;
`

export const ModalTitle = styled.h3`
  font-family: 'HelveticaNeueRoman';
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.78;
  font-weight: 400;
  color: #000000;
  span{
    color: #fe5000; 
    font-weight: 700;
  }
`
export const ModalText = styled.p`
  font-family: 'HelveticaNeueRoman';
  font-weight: 400;
  color: #000000;
  line-height: 1.5;
  font-size: 16px;
  b{
    font-weight: 700;
  }
  margin-bottom: 32px;
`

export const Border = styled.span`
  background-image: url(${border});
  background-size: contain;
    width: 15px;
    height: calc(100% - 2px);
    background-repeat: no-repeat;
    background-position: top center;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);

`

export const Bt = styled.button`
  padding: 12px 32px;
  border: none;
  height: 40px;
  font-size: 15px;
  font-weight: 500;
  border-radius: ${theme.border.radiusBt};
  font-family: 'HelveticaNeueRoman';
  background-color: ${theme.colors.black};
  color: white;
`