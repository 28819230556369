import React, { useState } from "react";
import { set, useForm } from "react-hook-form";
import axios from 'axios'

import * as S from './styles'

const Login = () => {
  const [load, setLoad] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSubmit = data => {
    setLoad(true)
    axios.post('https://nsps.nike.t4.com.br/api/Auth/Authenticate', {
      "login": data.login,
      "password": data.password,
    })
    .then(response => {
      setLoad(false)
      const auth = {
        token: response.data.token,
        storeId: response.data.id
      }

      localStorage.setItem('auth', JSON.stringify(auth))
      window.location.href = "/"
    })
    .catch(error => {
      setLoad(false)
      alert('Usuário e/ou senha errado(s)')
    })
  }
  
  return (
    <S.Wrapper>
      <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
        <S.Label htmlFor="login">Login:</S.Label>
        <S.Input
          placeholder="Nome de usuário"
          {...register("login", {
            required: "Preencha esse campo"
          })}
        />
        {errors.login && <S.Error>{errors.login.message}</S.Error>}

        <S.Label htmlFor="password">Senha:</S.Label>
        <S.Input
          placeholder="Senha de usuário"
          type="password"
          {...register("password", {
            required: "Preencha esse campo"
          })}
        />
        {errors.password && <S.Error>{errors.password.message}</S.Error>}

        <S.BtSubmit type="submit" disabled={load}>Entrar</S.BtSubmit>
      </S.FormContainer>
      
      {load &&
        <div id="fountainG">
          <div id="fountainG_1" className="fountainG"></div>
          <div id="fountainG_2" className="fountainG"></div>
          <div id="fountainG_3" className="fountainG"></div>
          <div id="fountainG_4" className="fountainG"></div>
          <div id="fountainG_5" className="fountainG"></div>
          <div id="fountainG_6" className="fountainG"></div>
          <div id="fountainG_7" className="fountainG"></div>
          <div id="fountainG_8" className="fountainG"></div>
        </div>
      }
    </S.Wrapper>
  );
};

export default Login;
