import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.li`
  @media screen and (min-width: 768px) and (max-width: 953px) {
    width: calc((100% / 4) - 18px);
    &:not(:nth-child(4n+4)){
      margin-right: 9px;
    }
  }
  width: 200px;
  height: 264px;
  background-image: url(${props => props.imgUrl});
  margin-bottom: ${theme.spacings.small};
  list-style: none;
  box-shadow: 0 0 12px 0 rgba(152,159,167,0.24);
  border-radius: 8px;
  &:not(:nth-child(4n+4)){
    margin-right: ${theme.spacings.small};
  }
  &:last-child{
    margin-right: 0;
  }
  &:nth-child(4n+2),
  &:nth-child(4n+1){
    position: relative;
    top: 83px;
  }
  
`