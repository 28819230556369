import React from 'react'
import * as S from './styles'

const ProgressItem = ({ text = 'default', number = '1', active = 1 }) => (
  <S.Wrapper active={active}>
    <S.Line number={number} className='step-line'/>
    <S.Category className='step-text'>{text}</S.Category>
  </S.Wrapper>
)

export default ProgressItem