import styled, { css } from 'styled-components'
import theme from '../../styles/theme'

const wrapperModifiers = {
  normal: () => css`
    height: 40px;
    font-size: 15px;
    font-weight: 500;
    border-radius: ${theme.border.radiusBt};
    font-family: 'HelveticaNeue';
    a{
      padding: 12px 32px;

    }
  `,

  medium: () => css`
    span{
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    img{
      margin-left: 8px;
    }
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    height: 40px;
    border-radius: ${theme.border.radiusBt};
    a{
      padding: 12px 24px;
      
    }
  `,

  large: () => css`
    height: 56px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1.83px;
    text-align: center;
    color: white;
    a{
      padding: 0 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    border-radius: ${theme.border.radiusGiga};
    
  `,

  orange: () => css`
    background-color: ${theme.colors.orange};
  `,

  transparent: () => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.gray};
  `,

  black: () => css`
    background-color: ${theme.colors.black};
    color: white;
  `,

  // transparent: () => css`
  //   border: solid 1px ${theme.colors.gray};
  //   background-color: ${theme.colors.softGray};
  //   border-radius: ${theme.border.radiusBt};
  //   padding: 0 24px;
  // `,

  gray: () => css`
    border: solid 1px ${theme.colors.gray};
    background-color: transparent;  
    color: ${theme.colors.darkGray};
  `
}

export const Wrapper = styled.button`
  font-family: 'TradeGothic';
  border: none;
  text-transform: ${props => props.uppercase ? 'uppercase' : ''};
  &:disabled{
    opacity: 0.5;
  }
  .link{
    color: inherit;
    text-decoration: inherit;
    height: 100%;
  }
  ${props => props.color ? wrapperModifiers[props.color] : wrapperModifiers['default']};

  ${props => props.size ? wrapperModifiers[props.size] : wrapperModifiers['default']};
`