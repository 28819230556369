import styled from 'styled-components'

export const Wrapper = styled.footer`
  position: relative;
  background-color: #f7f7f7;
  border-top: 1px solid #e8e8e8;
  width: 100%;
  padding: 33px 0;
  border-bottom: 8px solid #000000;
  .timer-block{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`

export const Text = styled.p`
  font-family: "HelveticaNeueRoman";
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #54595e;
`