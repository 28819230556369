import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.li`
  width: 160px;
  list-style: none;
  opacity: 0.48;
  padding-top: 6px;

  &:not(:last-child){
    margin-right: 16px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 16vw;

    &:not(:last-child){
      margin-right: 14px;

    }
  }
  &:nth-child(${props => props.active}){
    opacity: 1;
    .step-line{
      background-color: ${theme.colors.orange};
      &:after{
        background-color: ${theme.colors.orange};
      }
    }
    .step-text{
      color: ${theme.colors.orange};
    }
  }
`

export const Line = styled.span`
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 10px;
  color: black;
  height: 2px;
  border-radius: 2px;
  background-color: ${theme.colors.gray};
 
  &:after{
    content: '${props => props.number}';
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 2.29;
    letter-spacing: -0.47px;
    text-align: center;
    color: #ffffff;
    background-color: ${theme.colors.gray};
    position: absolute;
    font-family: "TradeGothic";
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`

export const Category = styled.h3`
  font-family: "HelveticaNeueLight";
  font-size: 12px;
  line-height: 1.33;
  color: ${theme.colors.gray};
  text-align: center;

`