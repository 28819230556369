import styled from 'styled-components'

export const Wrapper = styled.section`
  background-color: #f7f7f7;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  #fountainG{
    position:relative;
    width:234px;
    height:28px;
    margin-top: 10px;
  }

  .fountainG{
    position:absolute;
    top:0;
    background-color:rgb(254,80,0);
    width:28px;
    height:28px;
    animation-name:bounce_fountainG;
      -o-animation-name:bounce_fountainG;
      -ms-animation-name:bounce_fountainG;
      -webkit-animation-name:bounce_fountainG;
      -moz-animation-name:bounce_fountainG;
    animation-duration:1.5s;
      -o-animation-duration:1.5s;
      -ms-animation-duration:1.5s;
      -webkit-animation-duration:1.5s;
      -moz-animation-duration:1.5s;
    animation-iteration-count:infinite;
      -o-animation-iteration-count:infinite;
      -ms-animation-iteration-count:infinite;
      -webkit-animation-iteration-count:infinite;
      -moz-animation-iteration-count:infinite;
    animation-direction:normal;
      -o-animation-direction:normal;
      -ms-animation-direction:normal;
      -webkit-animation-direction:normal;
      -moz-animation-direction:normal;
    transform:scale(.3);
      -o-transform:scale(.3);
      -ms-transform:scale(.3);
      -webkit-transform:scale(.3);
      -moz-transform:scale(.3);
    border-radius:19px;
      -o-border-radius:19px;
      -ms-border-radius:19px;
      -webkit-border-radius:19px;
      -moz-border-radius:19px;
  }

  #fountainG_1{
    left:0;
    animation-delay:0.6s;
      -o-animation-delay:0.6s;
      -ms-animation-delay:0.6s;
      -webkit-animation-delay:0.6s;
      -moz-animation-delay:0.6s;
  }

  #fountainG_2{
    left:29px;
    animation-delay:0.75s;
      -o-animation-delay:0.75s;
      -ms-animation-delay:0.75s;
      -webkit-animation-delay:0.75s;
      -moz-animation-delay:0.75s;
  }

  #fountainG_3{
    left:58px;
    animation-delay:0.9s;
      -o-animation-delay:0.9s;
      -ms-animation-delay:0.9s;
      -webkit-animation-delay:0.9s;
      -moz-animation-delay:0.9s;
  }

  #fountainG_4{
    left:88px;
    animation-delay:1.05s;
      -o-animation-delay:1.05s;
      -ms-animation-delay:1.05s;
      -webkit-animation-delay:1.05s;
      -moz-animation-delay:1.05s;
  }

  #fountainG_5{
    left:117px;
    animation-delay:1.2s;
      -o-animation-delay:1.2s;
      -ms-animation-delay:1.2s;
      -webkit-animation-delay:1.2s;
      -moz-animation-delay:1.2s;
  }

  #fountainG_6{
    left:146px;
    animation-delay:1.35s;
      -o-animation-delay:1.35s;
      -ms-animation-delay:1.35s;
      -webkit-animation-delay:1.35s;
      -moz-animation-delay:1.35s;
  }

  #fountainG_7{
    left:175px;
    animation-delay:1.5s;
      -o-animation-delay:1.5s;
      -ms-animation-delay:1.5s;
      -webkit-animation-delay:1.5s;
      -moz-animation-delay:1.5s;
  }

  #fountainG_8{
    left:205px;
    animation-delay:1.64s;
      -o-animation-delay:1.64s;
      -ms-animation-delay:1.64s;
      -webkit-animation-delay:1.64s;
      -moz-animation-delay:1.64s;
  }



  @keyframes bounce_fountainG{
    0%{
    transform:scale(1);
      background-color:rgb(254,80,0);
    }

    100%{
    transform:scale(.3);
      background-color:rgb(0,0,0);
    }
  }

  @-o-keyframes bounce_fountainG{
    0%{
    -o-transform:scale(1);
      background-color:rgb(254,80,0);
    }

    100%{
    -o-transform:scale(.3);
      background-color:rgb(0,0,0);
    }
  }

  @-ms-keyframes bounce_fountainG{
    0%{
    -ms-transform:scale(1);
      background-color:rgb(254,80,0);
    }

    100%{
    -ms-transform:scale(.3);
      background-color:rgb(0,0,0);
    }
  }

  @-webkit-keyframes bounce_fountainG{
    0%{
    -webkit-transform:scale(1);
      background-color:rgb(254,80,0);
    }

    100%{
    -webkit-transform:scale(.3);
      background-color:rgb(0,0,0);
    }
  }

  @-moz-keyframes bounce_fountainG{
    0%{
    -moz-transform:scale(1);
      background-color:rgb(254,80,0);
    }

    100%{
    -moz-transform:scale(.3);
      background-color:rgb(0,0,0);
    }
  }
`

export const FormContainer = styled.form`
  border-radius: 4px;
  box-shadow: 0 11px 14px -10px #e8e8e8;
  background-color: white;
  border-left: 3px solid #fe5000;
  /* height: 146px; */
  padding: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Label = styled.label`
  color: #54595e;
  opacity: 0.9;
  font-size: 14px;
  line-height: 1.71;
  font-family: "HelveticaNeueLight";
  font-weight: 600;
  /* margin-bottom: 7px; */
`

export const Input = styled.input`
  border: 1px solid #54595e;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 22px;
`

export const BtSubmit = styled.button`
  background-color: #fe5000;
  border: none;
  border-radius: 100px;
  padding: 10px 25px;
  width: fit-content;
  margin: 0 auto;
  color: white;
  font-weight: 600;
  font-family: "HelveticaNeueLight";
`

export const Error = styled.span`
  display: block;
  margin-top: -13px;
  margin-bottom: 14px;
  color: #fe5000;
  font-weight: 700;
  font-family: "HelveticaNeueLight";
`