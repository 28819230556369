import styled from 'styled-components'

export const Wrapper = styled.div`
  border-bottom: 8px solid #000000;
  position: relative;
  .undo-bt{
    position: absolute;
    top: 25px;
    left: 53px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      top: -7px;
      left: 27px;
    }
  }
  iframe{
    height: 1110px;
    border: none;
    width: 100%;
  }
  .timer-block{
    position: absolute;
    bottom: 24px;
    right: 0;
  }
`