const theme = {
  border: {
    radiusBt: "24px",
    radiusGiga: "32px",
  },
  font: {
    family:
      "TradeGothic, HelveticaNeueRoman, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    bold: 600,
  },
  colors: {
    softGray: "#f7f7f7",
    lightGray: "#e8e8e8",
    gray: "#bfc1c1",
    darkGray: "#54595e",
    black: "#000000",
    orange: "#fe5000",
  },
  spacings: {
    xsmall: "16px",
    small: "24px",
    medium: "32px",
    large: "40px",
    xlarge: "48px",
    container: "872px"
  }
};

export default theme;
