import React from 'react'
import * as S from './styles'

import pathsUrl from '../../data/NameCardInfo.json'

import logoCampanha from '../../assets/campanha.svg'
import Button from '../../components/Button'
import NameCard from '../../components/NameCard'
import LogoNike from '../../components/LogoNike'


import redirectLogin from '../../functions/redirectLogin'

const Home = () => {
  React.useEffect(() =>{
    redirectLogin()
    localStorage.removeItem('obj')
    localStorage.removeItem('sub')
  }, [])
  return (

    <S.Wrapper>
      <div className="progress">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
        <LogoNike />
      </div>

      <S.DoubleContent>
        <img 
          className="service-logo" 
          src={logoCampanha} 
          alt="Texto escrito feito por você em cima do swoosh da Nike" 
        />

        <div>
          <S.Title>Personalize<br/> o seu Nike favorito</S.Title>

          <S.SmallText>Escolha entre as opções disponíveis
            <br/> e deixe do seu jeito!
          </S.SmallText>

          <Button 
            size="large" 
            color="orange" 
            uppercase={true}
            href={'/categoria'}
          >
            Iniciar Personalização
          </Button>

        </div>
      </S.DoubleContent>

      <S.WrapperCards>
        {pathsUrl.map((path, index) => <NameCard key={index} imgUrl={path} />)}
      </S.WrapperCards>

    </S.Wrapper>
  )
}

export default Home