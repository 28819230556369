import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 88px;
  height: 32px;
  border-radius: 4px 0px 0px 4px;
  box-shadow: 0 -8px 16px 0 #eeeeee;
  display: flex;
  align-items: center;
  padding-left: 12px;
  background-color: ${props => props.timer > 10 ? 'white' : '#f90218'};
  .clock {
    margin-right: 8px;
  }
`

export const Time = styled.span`
  font-family: 'HelveticaNeueRoman';
  font-size: 12px;
  line-height: 1.33;
  color: ${props => props.timer > 10 ? '#54595e' : 'white'};
`