import styled from 'styled-components'
import theme from '../../styles/theme'

export const Wrapper = styled.section`
  background-color: ${theme.colors.softGray};
  padding: 48px 76px 108px;
  position: relative;

  min-height: calc(100vh - 340px);

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 48px 40px 108px;
  }
  .subtitle{
    margin-bottom: 40px;
  }
  .undo-bt{
    position: absolute;
    top: 25px;
    left: 53px;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      left: 27px;
    }
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 704px;
  width: 928px;
  overflow-y: auto;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }
  &::-webkit-scrollbar {  
    width: 8px;
    border-radius: 4px;
    background-color: #e8e8e8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #54595e;
    border-radius: 4px;
  }
`

export const ButtonContent = styled.div`
  margin: 16px auto 0;
  width: fit-content;
  .bt{
    &:not(:last-child){
      margin-right: 16px;
    }
  }
`