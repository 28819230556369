import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import * as S from './styles' 

import clock from '../../assets/icons/time-machine.svg'
import dangerClock from '../../assets/icons/danger-timer.svg'

const Timer = ({timer}) => {
  const timeout = 120000
  const [remaining, setRemaining] = useState(timeout)

  const handleOnIdle = () => window.location.href = "/";

  const {
    getRemainingTime
  } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  })

  useEffect(() => {
    setRemaining(getRemainingTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return(
    <S.Wrapper className='timer-block' timer={remaining / 1000}>
      <img className='clock' alt="Imagem de um relógio" src={(remaining / 1000 ) > 10 ? clock : dangerClock} />
      <S.Time timer={remaining / 1000}>{Math.floor(remaining / 1000) + 1}</S.Time>
    </S.Wrapper>
  )
}

export default Timer