import React from 'react'
import * as S from './styles'

const ProgressTitle = ({currentStep, nameCategory, textPage}) => (
  <S.Wrapper>
    <S.CurrentStep>{currentStep}/5</S.CurrentStep>
    <S.TitleCategory> 
      {textPage}<span>{nameCategory}</span>
    </S.TitleCategory>
  </S.Wrapper>
)

export default ProgressTitle