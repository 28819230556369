import styled from 'styled-components'

import bgPage from '../../assets/bg-finalizar.svg'


export const Wrapper = styled.main`
  padding: 80px 0 50px;
  background-color: #f7f7f7;
  background-image: url(${bgPage});
  background-repeat: no-repeat;
  background-position: top right;
  padding-left: 76px;
  min-height: calc(100vh - 340px);
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding-left: 40px;
    background-position: 50vw 0px;

  }
  .qr-code{ 
    width: 144px;
    height: 144px;
    box-shadow: 0 -8px 16px 0 #eeeeee;
    border: solid 2px #fe5000;
    margin-right: 24px;
  }
`

export const Maintitle = styled.h2`
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.8px;
  color: #fe5000;
  font-family: 'HelveticaNeueLight';
  font-weight: 700;
  margin-bottom: 16px;
`

export const SubMaintitle = styled.p`
  font-family: 'HelveticaNeueRoman';
  line-height: 1.5;
  color: #54595e;
  width: 424px;
  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-family: 'HelveticaNeueRoman';
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  margin-bottom: 16px;

  span{
    color: #fe5000; 
    font-weight: 700;
  }
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const TitleNumber = styled.h4`
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'HelveticaNeueLight';
  color: #000000;
`

export const OrderNumber = styled.span`
  font-family: 'HelveticaNeueRoman';
  font-size: 32px;
  line-height: 1;
  color: #fe5000;
`

export const FlowingText = styled.h6`
  font-family: 'HelveticaNeueRoman';
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 8px;
  width: 424px;
`

export const BodyText = styled.h5`
  font-family: 'HelveticaNeueRoman';
  font-size: 12px;
  line-height: 1.33;
  color: #54595e;
`

export const InfoBlock = styled.div`
  margin-top: 24px;
`

export const InfoTitle = styled.h2`
  font-size: 16px;
  font-family: 'HelveticaNeueRoman';
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 8px; 
  color: #000000;
  span{
    color: #fe5000
  }
`

export const InfoResult = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #54595e;
  font-family: 'HelveticaNeueRoman';
  font-weight: 400;
`

export const InfoSection = styled.div`
  margin-bottom: 24px;
`

export const BoxInfos = styled.div`
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-gap: 16px;
  width: 496px;
`